import {Redirect, Router} from 'aurelia-router';
import * as environment from "../config/environment.json";
import {autoinject, bindable, PLATFORM} from 'aurelia-framework';
import {UserService} from "./resources/services/UserService";
import * as Fhir from "resources/classes/FhirModules/Fhir";
import {Questionnaire, QuestionnaireResponse, Tools} from "resources/classes/FhirModules/Fhir";
import {I18N} from "aurelia-i18n";
import {DialogMessages} from 'resources/services/DialogMessages';
import * as moment from "moment";
import {HttpClient} from "aurelia-http-client";
import {qGrafixx} from "./resources/elements/questionnaire/grafixx/q-grafixx";
import {IQuestionnaireList, QuestionnaireService} from "./resources/services/QuestionnaireService";
import {ConfigService} from "./resources/services/ConfigService";
import {RuntimeInfo} from "./resources/classes/RuntimeInfo";
import {LocationService} from "./resources/services/LocationService";
import {NitTools} from "./resources/classes/NursitTools";
import {FhirService} from "./resources/services/FhirService";
import {PatientService} from "./resources/services/PatientService";
import {PatientChangeNotifier} from "./resources/services/PatientChangeNotifier";
import {AnalyzerV1} from 'resources/services/Analyzers/V1';
import {ICDService} from 'resources/services/ICDService';
import {ReportService} from 'resources/services/ReportService';
import {AnalyzerEpa22} from "./resources/services/Analyzers/epa2_2";
import {AnalyzerEpaKIDS22} from "./resources/services/Analyzers/epaKIDS_2.2";
import {EncounterService} from "./resources/services/EncounterService";
import {AnalyzerLTC1201} from "./resources/services/Analyzers/epaLTC_1.2.01";
import cssVars from 'css-vars-ponyfill';
import {AnalyzeService} from "./resources/services/analyzeService";
import {AnalyzerEpaAC2_3} from "./resources/services/Analyzers/epaAC2_3";
import {AnalyzerEpaPSYC} from "./resources/services/Analyzers/epaPSYC";
import {PatientItem} from "./resources/classes/Patient/PatientItem";
import {OrganizationService} from "./resources/services/OrganizationService";
import {AnalyzerVHNOE} from "./resources/services/Analyzers/vhNOE";
import {DialogService} from "aurelia-dialog";
import {AnalyzerSEMPA} from "./resources/services/Analyzers/sempa";
import {HomeList} from "./views/list";
import {AnalyzerSempaIntensiv} from "./resources/services/Analyzers/sempa-intensiv";
import {translations} from "./resources/classes/translations"; // see: https://jhildenbiddle.github.io/css-vars-ponyfill/#/  - used for style fixing for IE11
import {Login} from 'views/login';
import {AnalyzerSempaKids} from "./resources/services/Analyzers/sempa-kids";
import {NursingVisitsProtocol} from "./views/patient/nursing-visits-protocol";
import {PermissionService} from 'resources/services/PermissionService';
import {LogoutService} from "./resources/services/LogoutService";

const abp = require("aurelia-bootstrap-datetimepicker");
require("jquery");
if (!window["jQuery"])
    window["jQuery"] = require('jquery');

require('bootstrap');
require("./css/style.less");

@autoinject
export class App {
    //#region Vars
    @bindable patients: PatientItem[] = [];
    @bindable selectedWard: any = undefined;
    wards: any[] = [];
    locationService: LocationService;
    dialogMessages: DialogMessages;
    userService: UserService;
    router: Router;
    i18n: I18N;
    patientService: PatientService;
    patientChangeNotifier: PatientChangeNotifier;
    startUpMessage: string = undefined;
    hideSideBar: boolean = false;
    qList: IQuestionnaireList;

    get loadingMessage() {
        return RuntimeInfo.MessageText;
    }

    get isLoading(): boolean {
        return RuntimeInfo.IsLoading;
    }

    get patient(): PatientItem {
        return App.Patient;
    }

    get style(): string {
        return RuntimeInfo.Style;
    }

    get isEmbedded(): boolean {
        return RuntimeInfo.Embedded;
    }

    get printFrame(): HTMLDivElement {
        return RuntimeInfo.PrintFrame;
    }

    // this is set from html template with ref=printFrame
    set printFrame(value: HTMLDivElement) {
        RuntimeInfo.PrintFrame = value;
    }

    get printDialog(): HTMLDivElement {
        return RuntimeInfo.PrintDialog;
    }

    //#endregion

    //#region static vars
    public static currentWardId: string = undefined;
    public static i18n: any = undefined;
    public static PatientList: HomeList;

    public static get Patient(): PatientItem {
        return PatientItem.SelectedPatient || PatientItem.LastLoadedPatient;
    }

    public static lastPrintPreviewId: string = undefined;
    public static Instance: App;
    //#endregion

    constructor(icdService: ICDService, dialogMessages: DialogMessages, userService: UserService, router: Router, i18n: I18N, locationService: LocationService, patientService: PatientService, patientChangeNotifier: PatientChangeNotifier,
        protected dialogService: DialogService,
                logoutService : LogoutService) {
        if (/hideList=1/gi.test(window.location.href)) {
            this.hideSideBar = true;
        }

        RuntimeInfo.ConsoleEnabled = false;

        this.dialogMessages = dialogMessages;
        this.userService = userService;
        this.i18n = i18n;
        App.i18n = i18n;
        PatientItem.i18n = i18n;
        this.router = router;
        this.locationService = locationService;
        this.patientService = patientService;
        this.patientChangeNotifier = patientChangeNotifier;

        App.RegisterAnalyzerClasses(icdService, this.i18n, this.patientChangeNotifier, this.userService, this.dialogService);
        App.Instance = this;

        window.onmousemove = () => logoutService.refreshLogoutTimer();
        if (ConfigService.Debug) {
            window["app"] = this;
            window["locationService"] = this.locationService;
            window["NursingVisitsProtocol"] = NursingVisitsProtocol;
            window["moment"] = moment;
            window["PatientItem"] = PatientItem;
            window["OrganizationService"] = OrganizationService;
            window["App"] = App;
            window["LocationService"] = LocationService;
            window["ReportService"] = ReportService;
            window["NitTools"] = NitTools;
            window["FhirTools"] = Tools;
            window["PatientService"] = PatientService;
            window["UserService"] = UserService;
            window["Fhir"] = Fhir;
            window["FhirService"] = FhirService;
            window["QuestionnaireService"] = QuestionnaireService;
            window["Questionnaire"] = Questionnaire;
            window["RuntimeInfo"] = RuntimeInfo;
            window["QuestionnaireResponse"] = QuestionnaireResponse;
            window["EncounterService"] = EncounterService;
            window["ConfigService"] = ConfigService;
            window["DialogMessages"] = DialogMessages;
            window["I18N"] = i18n;
            window["AnalyzeService"] = AnalyzeService;
            window['ReportService'] = ReportService;
        }
    }

    configureRouter(config) {
        config.title = this.i18n.tr("careit_name");

        if (!this.userService.checkEmbedded()) config.addAuthorizeStep(AuthorizeStep);
        config.addAuthorizeStep(PermissionStep);
        config.map([
            {
                route: 'admin',
                name: 'admin',
                settings: { noSidebar: true },
                viewPorts: {
                    left: { moduleId: null },
                    main: { moduleId: PLATFORM.moduleName('views/admin/careit-admin') }
                }
            },
            {
                route: 'launch',
                name: 'launch',
                settings: { noSidebar: true },
                viewPorts: {
                    left: { moduleId: null },
                    main: { moduleId: PLATFORM.moduleName('views/launch') }
                }
            },
            {
                route: ['encounter/:id', 'encounter'],
                name: 'encounter',
                viewPorts: {
                    left: { moduleId: PLATFORM.moduleName('views/list') },
                    main: { moduleId: PLATFORM.moduleName('views/encounter') }
                },
            },
            {
                route: [''],
                name: 'home',
                viewPorts: {
                    left: { moduleId: PLATFORM.moduleName('views/list') },
                    main: { moduleId: PLATFORM.moduleName('views/encounter') }
                },
            },
            {
                route: 'ward/:id',
                name: 'ward',
                viewPorts: {
                    left: { moduleId: PLATFORM.moduleName('views/list') },
                    main: { moduleId: PLATFORM.moduleName('views/bedit/bedit-root') }
                },
            },
            {
                route: 'visit/:id',
                name: 'visit',
                viewPorts: {
                    left: { moduleId: null },
                    main: { moduleId: PLATFORM.moduleName('views/visit') }
                },
            },
            {
                route: 'visitExtended/:id/:employeeGivenName/:employeeFamilyName/:userId',
                name: 'visitExtended',
                viewPorts: {
                    left: { moduleId: PLATFORM.moduleName('views/list') },
                    main: { moduleId: PLATFORM.moduleName('views/visitExtended') }
                }
            },
            {
                route: 'wardExtended/:nameOrId/:employeeGivenName/:employeeFamilyName/:userId',
                name: 'wardExtended',
                viewPorts: {
                    left: { moduleId: null },
                    main: { moduleId: PLATFORM.moduleName('views/ward-extended') }
                }
            },
            {
                route: 'wardExtended/:nameOrId',
                name: 'wardExtendedSimple',
                viewPorts: {
                    left: { moduleId: null },
                    main: { moduleId: PLATFORM.moduleName('views/ward-extended') }
                }
            },
            {
                route: ['login', 'login:id'],
                name: 'login',
                settings: { noSidebar: true },
                viewPorts: {
                    left: { moduleId: null },
                    main: { moduleId: PLATFORM.moduleName('views/login') }
                },
            },
            {
                route: ['login-rbac'],
                name: 'login-rbac',
                settings: { noSidebar: true },
                viewPorts: {
                    left: { moduleId: null },
                    main: { moduleId: PLATFORM.moduleName('views/login-rbac') }
                },
            },
            {
                route: 'ward-shift-report/:id',
                name: 'ward-shift-report',
                viewPorts: {
                    left: { moduleId: PLATFORM.moduleName('views/list') },
                    main: { moduleId: PLATFORM.moduleName('views/ward-shift-report') }
                },
            },
            {
                route: 'patient-risk-overview/:id',
                name: 'patient-risk-overview',
                viewPorts: {
                    left: { moduleId: null },
                    main: {
                        moduleId: PLATFORM.moduleName('views/standalone/standalone-patient-risk-overview')
                    }
                }
            }
        ]);
    }

    async activate() {
        this.userService.getCurrentUserRole();
        PatientItem.i18n = this.i18n;
        this.startUpMessage = await ConfigService.Load(this.i18n);
        App.SetPickerOptions();
        qGrafixx.loadBodyMap()
            .catch(error => {
                console.warn(JSON.stringify(error));
            })
    }

    public static SetPickerOptions() {
        const pickerConfig = new abp.PickerConfig();

        pickerConfig.options.tooltips = {
            today: translations.translate('go_to_today'),
            clear: translations.translate('clear'),
            close: translations.translate('close_picker'),
            selectMonth: translations.translate('select_month'),
            prevMonth: translations.translate('prev_month'),
            nextMonth: translations.translate('next_month'),
            selectYear: translations.translate('select_year'),
            prevYear: translations.translate('prev_year'),
            nextYear: translations.translate('next_year'),
            selectDecade: translations.translate('select_decade'),
            prevDecade: translations.translate('prev_decade'),
            nextDecade: translations.translate('next_decade'),
            prevCentury: translations.translate('prev_century'),
            nextCentury: translations.translate('next_century'),
            pickHour: translations.translate('pick_hour'),
            incrementHour: translations.translate('increment_hour'),
            decrementHour: translations.translate('decrement_hour'),
            pickMinute: translations.translate('pick_minute'),
            incrementMinute: translations.translate('increment_minute'),
            decrementMinute: translations.translate('decrement_minute'),
            pickSecond: translations.translate('pick_second'),
            incrementSecond: translations.translate('increment_second'),
            decrementSecond: translations.translate('decrement_second'),
            togglePeriod: translations.translate('toggle_period'),
            selectTime: translations.translate('select_time')
        };

        pickerConfig.options.useCurrent = false;
        pickerConfig.options.format = RuntimeInfo.DateTimeFormat;
        pickerConfig.options.locale = RuntimeInfo.Language;
        pickerConfig.options.showTodayButton = true;
        pickerConfig.options.showClear = true;
        pickerConfig.options.showClose = true;
        pickerConfig.options.widgetPositioning = {
            horizontal: 'left',
            vertical: 'auto'
        };
        pickerConfig.options.keepInvalid = true;
        pickerConfig.options.focusOnShow = true;
    }

    static RefreshTimerId: number;

    async created() {
        const loginCfg = ConfigService.cfg["login"];
        if (loginCfg && loginCfg["oauth2"]) {
            if (window.location.href.indexOf('oauth=0') === -1) {
                const oaConfig = loginCfg["oauth2"];
                ConfigService.UseOAuth2 = oaConfig && oaConfig.enabled === true;
            }

            // when oauth has been forced by URL
            if (window.location.href.indexOf('oauth=1') > -1) {
                ConfigService.UseOAuth2 = true;
            }

            if (ConfigService.UseOAuth2)
                this.userService.checkOAuthLoginTimer();
        }
    }

    async attached() {
        const params = NitTools.GetUrlParams();
        if (typeof params["login"] !== "undefined") {
            try {
                const urlHash = window.location.hash;
                let hash = params["login"]; // decodeURIComponent(params["sessionId"]);
                if (hash.indexOf('%') > -1)
                    hash = encodeURIComponent(hash);
                const [u, p] = atob(hash).split(':');
                await this.userService.login(u, p);
                await this.userService.loadData();
                this.router.navigate(urlHash);
            } catch (e) {
                console.warn(e.message || e);
            }
        }

        if (typeof params["sessionId"] !== "undefined") {
            try {
                Login.LoginRunning = true;
                let hash = params["sessionId"];
                if (hash.indexOf('%') > -1)
                    hash = encodeURIComponent(hash);

                const [u, p] = atob(hash).split(':');
                const result = await this.userService.login(u, p);
                if (result.success) {
                    window.setTimeout(() => {
                        if (App.PatientList && this.userService && this.userService.defaultWard) {
                            try {
                                App.PatientList.OnWardLoaded = () => App.PatientList.isFromLoginScreen = false;
                                App.PatientList.isFromLoginScreen = true;
                                App.PatientList.wardId = this.userService.defaultWard.id
                            } catch (e) {
                                console.warn(e);
                            } finally {

                            }
                        }
                    }, 500);
                } else {
                    throw result.message || result.error;
                }
            } catch (e) {
                console.warn(e.message || e);
            }
            finally {
                Login.LoginRunning = false;
            }
        }

        if (this.startUpMessage) {
            this.startUpMessage = this.i18n.tr(this.startUpMessage)
            this.dialogMessages.prompt(this.startUpMessage, this.i18n.tr('warning'), true);
        }

        cssVars({ watch: true });

        if (ConfigService.Debug && (window.location.href.indexOf('http://207.180.250.172/debug') > -1 || window.location.href.indexOf('http://localhost:3000') > -1)) {
            const a = document.createElement('a');
            a.href = 'http://207.180.250.172:8111';
            a.style.cursor = 'pointer';
            a.target = '_blank';

            const img = document.createElement('img');
            img.setAttribute('style', 'position: absolute; right: 44px;top: -3px;z-index: 1000;zoom: 0.8;');
            img.src = 'http://207.180.250.172:8111/app/rest/builds/buildType:id:CareITProWeb_DefaultBuild/statusIcon.svg';
            img.alt = 'TeamCity build status';
            a.appendChild(img)

            document.body.appendChild(a);
        }

        if (ConfigService.Debug) {
            window["ClearCurrentEncounter"] = async () => {
                if (window["RuntimeInfo"])
                    window["RuntimeInfo"].IsLoading = true;
                try {
                    let pat = PatientItem.LastLoadedPatient;
                    await PatientItem.Clear(pat);

                    pat = await PatientItem.Load(pat.encounterId);
                    this.patientChangeNotifier.notify(pat);
                    window.location.reload();
                } catch (e) {
                    console.warn(e.message || e);
                } finally {
                    if (window["RuntimeInfo"])
                        window["RuntimeInfo"].IsLoading = false;
                }
            }
        }
    }

    // this is set from html template with ref=printDialog
    set printDialog(value: HTMLDivElement) {
        RuntimeInfo.PrintDialog = value;
    }

    togglePatientList() {
        RuntimeInfo.TogglePatientList();
    }

    togglePatientListLG(hide?) {
        RuntimeInfo.TogglePatientListLG(hide);
    }

    closePreview() {
        this.printDialog.style.display = "none";
        if (App.lastPrintPreviewId) {
            try {
                let url = `${NitTools.IncludeTrailingSlash(ReportService.ReportServer)}api/Pdf/${App.lastPrintPreviewId}`;
                App.lastPrintPreviewId = undefined;
                new HttpClient().delete(url)
                    .catch(error => {
                        console.warn(error.message || error)
                    })
            } catch (error) {
                console.warn(error.message);
            }
        }
    }

    /**
     * Use this method to register different AnalyzerClasses to the AnalyzeService
     */
    public static RegisterAnalyzerClasses(icdService, i18n, patientChangeNotifier, userService, dialogService: DialogService) {
        new AnalyzerV1("V1", icdService, i18n, patientChangeNotifier, userService, dialogService).inject();
        new AnalyzerEpa22("EPA2.2", icdService, i18n, patientChangeNotifier, userService, dialogService).inject();
        new AnalyzerEpaAC2_3("EPAAC2.3", icdService, i18n, patientChangeNotifier, userService, dialogService).inject();
        new AnalyzerEpaPSYC("EPAPSYC", icdService, i18n, patientChangeNotifier, userService, dialogService).inject();
        new AnalyzerEpaKIDS22("EPAKIDS2.2", icdService, i18n, patientChangeNotifier, userService, dialogService).inject();
        new AnalyzerLTC1201("epaLTC_1.2.01", icdService, i18n, patientChangeNotifier, userService, dialogService).inject();
        new AnalyzerVHNOE("VHNOE", icdService, i18n, patientChangeNotifier, userService, dialogService).inject();
        new AnalyzerSEMPA("SEMPA", icdService, i18n, patientChangeNotifier, userService, dialogService).inject();
        new AnalyzerSempaIntensiv("SEMPA-INTENSIV", icdService, i18n, patientChangeNotifier, userService, dialogService).inject();
        new AnalyzerSempaKids("SEMPA-KIDS", icdService, i18n, patientChangeNotifier, userService, dialogService).inject();
        /* new AnalyzerSempaGHMother("Sempa-GH-Mother", icdService, i18n, patientChangeNotifier, userService, dialogService).inject();
        new AnalyzerSempaGHBaby("Sempa-GH-Baby", icdService, i18n, patientChangeNotifier, userService, dialogService).inject(); */
    }

    public static svg() {
        new HttpClient().get("images/abc.svg").then(async r => {
            let txt = r.response.replace(/>/g, ">\n").replace(/\n\n/g, "\n");
            let arr = txt.split('\n');

            for (let i = 0; i < arr.length; i++) {
                let s = arr[i].replace('<', '').replace('>', '').trim();
                if (s.indexOf("image ") === 0) {
                    let props = s.split(' ');
                    for (let p = 0; p < props.length; p++) {
                        if (props[p].indexOf('=') > -1) {
                            let key = props[p].split('=')[0];
                            let value = props[p].split('=')[1].replace(/"/g, '').trim();
                            if (/href/gi.test(key)) {
                                let rep = await new HttpClient().get(value);
                                let d = document.createElement('div');
                                d.innerHTML = rep.response;
                                let svg = $(d).find("svg")[0].innerHTML.replace(/\r\n/g, "").replace(/\n/g, "");
                                arr[i] = svg;
                            }
                        }
                    }
                } else if (arr[i].indexOf("</image>") === 0) {
                    arr[i] = "";
                }
            }
        });
    }
}

@autoinject
class AuthorizeStep {
    constructor(protected userService: UserService,
        protected router: Router,
        protected locationService: LocationService,
        protected i18n: I18N,
        protected logoutService : LogoutService
    ) {

    }

    async run(navigationInstruction, next) {
        let search = window.location.search || "?foo=bar";
        this.logoutService?.refreshLogoutTimer();

        if ((UserService.Login.usePrincipa || window.location.search.indexOf('login=') > -1 || window.location.href.indexOf('ensureUser') > -1) && /sessionId=/gi.test(search))
            return next();
        if (!ConfigService.ConfigLoaded) {
            await ConfigService.Load(this.i18n);
        }

        if (UserService.IsLoggedIn)
            await FhirService.EnsureFhirVersion();

        /********** OAuth2 direct call ************/
        const params = NitTools.GetUrlParams();
        if (window.location.href.indexOf('oauth=0') === -1) {
            if (!this.userService.isLoggedIn && ConfigService.UseOAuth2 && params.code) {
                // login using the given code value from the url.
                // this happens when it is a redirect from the smile-server login-screen
                // when this point is reached, we are sure that the credentials are valid,
                // so no further checking is needed
                // example url to be called from:
                //   http://vmd73685.contaboserver.net:9200/oauth/authorize?response_type=code&scope=online_access+openid+profile+patient/*.read&client_id=ciw&state=af0ifjsldkj&redirect_uri=http%3A%2F%2Flocalhost%3A3000
                Login.LoginRunning = true;
                try {
                    const result = await this.userService.loginOAuthCode(params.code);
                    ConfigService.SetOAuthTokenProperties(result);

                    // after the login was successful we need to get the username explizitely
                    // this is done by calling /userinfo of the OAuth-Base-Endpoint
                    const loginCfg = ConfigService.cfg["login"];
                    const oauthConfig = loginCfg["oauth2"];
                    const client = new HttpClient();
                    client.configure(x => {
                        x.withHeader("Authorization", `Bearer ${ConfigService.AccessToken}`)
                            .withBaseUrl(oauthConfig.endpoint)
                    });

                    const userInfoResult = await client.createRequest('userinfo').asGet().send();
                    let userInfo: any;
                    if (userInfoResult.response) {
                        userInfo = JSON.parse(userInfoResult.response);
                    }

                    // now assign the userinfo to the local properties and
                    // although set the needed sessionstorage items for the workflow
                    if (userInfo && userInfo.sub) {
                        UserService.UserFirstName = userInfo.given_name;
                        UserService.UserLastName = userInfo.family_name;
                        UserService.UserName = userInfo.sub.toUpperCase();

                        await this.userService.loadData(UserService.UserName);
                        UserService.IsLoggedIn = true;

                        sessionStorage.setItem('userRole', btoa(this.userService.isAdmin ? 'admin' : 'user'));
                        sessionStorage.setItem(environment.sessionName, btoa(UserService.UserName + ':dummy'));
                        if (this.userService.practitioner) {
                            sessionStorage.setItem('PractitionerId', this.userService.practitioner.id);
                        }

                        // start the refresh token timer:
                        this.userService.checkOAuthLoginTimer();

                        // use replaceState to remove the ?code stuff from the url or else it's a never ending login
                        window.history.replaceState({}, document.title, window.location.origin + window.location.pathname + "#/encounter");

                        return next();
                    } else {
                        // the userinfo does not contain the expected user informations.
                        // so just display the default login screen
                        Login.LoginRunning = false;
                        return next.cancel(new Redirect('login'));
                    }
                }
                catch (ex) {
                    console.warn(ex);
                    // something went boncas, so display the login-page
                    Login.LoginRunning = false;
                    return next.cancel(new Redirect('login'));
                }
            }
        }
        /******************** */

        if (window.location.hash === '#/launch' || window.location.href.indexOf('/launch') > -1) {
            if (window.location.href.indexOf('?iss') === -1) {
                const url = `${window.location.href}?iss=${FhirService.Endpoint}&launch=care_it_web`;
                window.location.href = url;
                return next();
            } else
                return next();
        }

        if (!navigationInstruction.getAllInstructions().some((route) => route.config.name === 'login')) {
            const result = await this.userService.checkLogin();

            if (!result.success) {
                if (window.location.href.indexOf('#') > -1) {
                    sessionStorage.setItem("returnUrl", window.location.href);
                }

                return next.cancel(new Redirect('login'));
            } else {
                await this.locationService.fetch();
                await QuestionnaireService.Fetch();
            }
        }

        return next();
    }
}

@autoinject
class PermissionStep {
    constructor(protected permissionService: PermissionService, protected userService: UserService) { 
    }

    async run(navigationInstruction, next) {
        if (!this.permissionService.checkActiveUserRole() && !navigationInstruction.getAllInstructions().some((route) => route.config.name.startsWith('login') || route.config.name.startsWith('admin'))) {
            return next.cancel(new Redirect('login-rbac'));
        }

        return next()
    }
}
